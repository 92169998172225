import $ from 'jquery';
import slick from 'slick-carousel';
import paroller from 'paroller.js';

var mywindowWidth = $(window).width();
var windowWidth = $(window).width();

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPod|iPad/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return ((isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()));
    }
};

function detectDevice() {
    var result = false;
    if (isMobile.any()) {
        result = true;
    }
    return result;
};

//----- Autostart/Loop Video (Unser Selbstverständnis) -----//
function isVisible(elem) {
    var $elem = $(elem);
    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}


$(document).ready(function() {

    //Height auf Datenschutz/Immpressum
    if (windowWidth > 1440) {
        $(".text-area").css("margin", "-800px 50px 50px 50px");
    }

    console.log(navigator.userAgent);
    //Slick Slider
    $('.regular').slick({
        dots: true,
        speed: 700,
        autoplay: true,
        arrows: false,
        adaptiveHeight: true
    });

    //Show Icons --> Unser Service
    if (detectDevice()) {
        $("#zauberstabpic").css("opacity", "0");
        $("#zauberstabgif").css("opacity", "1");
        $("#puzzlepic").css("opacity", "0");
        $("#puzzlegif").css("opacity", "1");
        $("#teampic").css("opacity", "0");
        $("#teamgif").css("opacity", "1");
        $("#abzeichenpic").css("opacity", "0");
        $("#abzeichengif").css("opacity", "1");
    } else {
        $("#zauberstab-service-box").hover(
            function() {
                $("#zauberstabpic").css("opacity", "0");
                $("#zauberstabgif").css("opacity", "1");
            },
            function() {
                $("#zauberstabpic").css("opacity", "1");
                $("#zauberstabgif").css("opacity", "0");
            }
        );

        $("#puzzle-service-box").hover(
            function() {
                $("#puzzlepic").css("opacity", "0");
                $("#puzzlegif").css("opacity", "1");
            },
            function() {
                $("#puzzlepic").css("opacity", "1");
                $("#puzzlegif").css("opacity", "0");
            }
        );

        $("#team-service-box").hover(
            function() {
                $("#teampic").css("opacity", "0");
                $("#teamgif").css("opacity", "1");
            },
            function() {
                $("#teampic").css("opacity", "1");
                $("#teamgif").css("opacity", "0");
            }
        );

        $("#abzeichen-service-box").hover(
            function() {
                $("#abzeichenpic").css("opacity", "0");
                $("#abzeichengif").css("opacity", "1");
            },
            function() {
                $("#abzeichenpic").css("opacity", "1");
                $("#abzeichengif").css("opacity", "0");
            }
        );
    }

    console.log(window.location);
    //Nur auf der Startseite
    if (window.location.pathname == "/") {
        $(window).on('load scroll', function() {
            if (isVisible("#selbstverstaendnis-video")) {
                $('#selbstverstaendnis-video').trigger('play');
                $('#selbstverstaendnis-video').attr('loop', 'loop');
            } else {
                $('video').trigger('pause');
            }
        });

        //----- Responsiv Header/Konatkt -----//
        $(window).on('resize load', function() {
            var windowWidth = $(window).width();
            var edge1borderWidth = ((windowWidth * 35) / 100);
            var edge2borderWidth = ((windowWidth * 65) / 100) + 20;

            //if (mywindowWidth != windowWidth) {
            if (windowWidth < 420) {
                document.getElementById("edge-1").style.borderWidth = "60px 0px 0px " + edge1borderWidth + "px";
                document.getElementById("edge-2").style.borderWidth = "60px " + edge2borderWidth + "px 0px 0px";
                document.getElementById("edge-3").style.borderWidth = "0 " + windowWidth + "px 45px 0px";
            } else {
                document.getElementById("edge-1").style.borderWidth = "100px 0px 0px " + edge1borderWidth + "px";
                document.getElementById("edge-2").style.borderWidth = "100px " + edge2borderWidth + "px 0px 0px";
                document.getElementById("edge-3").style.borderWidth = "0 " + windowWidth + "px 140px 0px";
            }
            //}
        });
    }
});

var navi = false;
$('#burger').click(function() {
    var deviceheight = $(window).height();
    if (!navi) {
        $('.burger').css('width', '25px');
        $('nav').addClass('is-open');
        this.classList.toggle('is-open');
        $('#ulnav').css('display', 'block');
        $('.kante-1').css('display', 'none');
        $('.kante-2').css('display', 'none');
        $('header').css('background', 'none');
        $('header').css('height', deviceheight + "px");
        $('#header-container').css('display', 'none');
        navi = true;
    } else {
        $('.burger').css('width', '18px');
        $('nav').removeClass('is-open');
        this.classList.toggle('is-open');
        $('.kante-1').css('display', 'block');
        $('.kante-2').css('display', 'block');
        $('header').css('background-image', 'linear-gradient(206deg, #015bf9, #17428d)');
        if (windowWidth < 420) {
            $('header').css('height', '305px');
        } else if (windowWidth < 1440) {
            $('header').css('height', '545px');
        }
        $('#header-container').css('display', 'block');
        $('#ulnav').css('display', 'none');
        navi = false;
    }
});

//Scroll-Top Button
$("#scrolltop").click(function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
});

$("#unser-unternhemen-button").click(function() {
    $('html, body').animate({
        scrollTop: $("#unser-unternhemen").offset().top
    }, 2000);
});

$("#unser-selbstverstaendnis-button").click(function() {
    $('html, body').animate({
        scrollTop: $("#unser-selbstverstaendnis").offset().top
    }, 2000);
});

$("#unser-service-button").click(function() {
    $('html, body').animate({
        scrollTop: $("#unser-service").offset().top
    }, 2000);
});

$("#kontakt-button").click(function() {
    $('html, body').animate({
        scrollTop: $("#kontakt").offset().top
    }, 2000);
});



$("#ulnav").click(function(e) {
    var bar = document.getElementById("ulnav");
    var nav = e.target.nodeName;
    console.log(e.target);
});
// $(document).scroll(function(e) {
//     var elementPosition = document.getElementById("selbstverstaendnis-video").offsetTop;
//     var scrollValue = window.scrollY;
//     if (scrollValue > elementPosition) {
//     }
// });